import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { primaryBgc, primaryBlue, secondary } from "../utils/jsutils";
import { ArrowForward } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";

function LearnMorePage() {
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  let items = [
    {
      header: "Executive Brief",
      title: "MSPs in the Fast Lane",
      body: "Keep pace with change, deliver top-tier customer service, and thrive in a dynamic market with 1Bonding. It’s the fast lane for MSPs, freeing up your most valuable asset – your IT talent – to deliver exceptional service.",
      link: "/files/1Bonding Executive Brief MSPs in the Fast Lane.pdf",
      img: "executive-brief.png",
    },
    {
      header: "Solution Brief",
      title: "The Hidden Tax of Collaboration Drag on MSPs",
      body: "MSPs rely on collaboration for success, but hidden inefficiencies known as collaboration drag can hinder productivity and growth. Learn about Collaboration drag – the friction and inefficiencies that impede effective teamwork.",
      link: "/files/1Bonding Solution Brief The Hidden Tax of Collaboration Drag on MSPs.pdf",
      img: "solution-brief-2.png",
    },
    {
      header: "Success Story",
      title: "Seamless Cross-Company Collaboration",
      body: "Discover how Acentix leveraged 1Bonding for seamless cross-company collaboration, automating service request exchanges while ensuring data privacy.",
      link: "/files/1Bonding Success Story Seamless Cross-Company Collaboration.pdf",
      img: "success-story.jpg",
    },
    {
      header: "Solution Brief",
      title: "Overcoming Service Management Integration Challenges",
      body: "This Solution Brief explores the common challenges faced in service management integration, and provides insights, strategies, and best practices to navigate the complexities.",
      link: "/files/1Bonding Solution Brief Overcoming Service Management Integration Challenges.pdf",
      img: "solution-brief-1.jpg",
    },
    {
      header: "How it Works",
      title: "What makes 1Bonding Unique?",
      body: "Delve deeper into how 1Bonding works to seamlessly exchange tickets, incidents, and service requests across organizations. 1Bonding's unique approach makes Service Management integrations fast, easy, and secure.",
      link: "/files/How 1Bonding Works.pdf",
      img: "how-it-works.jpg",
    },
  ];

  const handleDownload = (pdfLink) => {
    const anchor = document.createElement("a");
    anchor.href = pdfLink;
    anchor.download = pdfLink.split("/").pop(); // Extract filename from the link
    document.body.appendChild(anchor);
    anchor.click();
    // Remove the anchor from the document body
    document.body.removeChild(anchor);
  };

  return (
    <Box sx={{ backgroundColor: primaryBgc }}>
      <Container>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pb: 5,
            pt: 5,
          }}
        >
          <Typography
            variant={smallscreen ? "h5" : "h3"}
            sx={{ color: secondary, fontWeight: 800 }}
            align="center"
          >
            Seamless Service Management Integrations
          </Typography>
          <Typography
            variant={smallscreen ? "h5" : "h3"}
            sx={{ color: primaryBlue }}
            align="center"
          >
            <i>Fast. Easy. Secure.</i>
          </Typography>
        </Stack>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid
                item
                xs={12}
                md={4}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
                  alignItems: "center",
                }}
              >
                <Paper elevation={0} sx={{ maxWidth: 375, overflow: "clip" }}>
                  <Box
                    sx={{
                      height: 180,
                      backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(images/${item.img})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                  <Stack sx={{ p: 3, mt: -5 }}>
                    <Stack sx={{ minHeight: 300 }}>
                      <Typography
                        variant="h6"
                        style={{ color: primaryBlue, fontWeight: 600 }}
                      >
                        <i>{item.header}</i>
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h3"
                        style={{ fontWeight: 700, color: secondary }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginTop: "10px",
                          color: primaryBlue,
                        }}
                      >
                        {item.body}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        endIcon={<ArrowForward />}
                        style={{ marginTop: "10px", color: primaryBlue }}
                        onClick={() => handleDownload(item.link)}
                      >
                        {item.header}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper elevation={0} sx={{ maxWidth: 375, overflow: "clip" }}>
                <Box
                  sx={{
                    height: 180,
                    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(images/calltoaction.jpeg)`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></Box>
                <Stack sx={{ p: 3, mt: -5 }}>
                  <Stack
                    sx={{
                      minHeight: 300,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="h3"
                      style={{ fontWeight: 700, color: secondary }}
                      align="center"
                    >
                      Ready to see 1Bonding in action?
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        marginTop: "10px",
                        color: secondary,
                        fontWeight: 700,
                      }}
                      align="center"
                    >
                      Schedule a demo today.
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      color="secondary"
                      style={{ marginTop: "10px", color: primaryBlue }}
                      to="/contact-us"
                      component={NavLink}
                    >
                      Let's talk
                    </Button>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Box>
  );
}

export default LearnMorePage;
