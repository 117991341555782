import React, { useState, useMemo, useEffect } from "react";
import {
  primaryBlue,
  timeElapsedFromEpoc,
  primaryBgc,
} from "../../../utils/jsutils";
import {
  getLatestTransaction,
  getOldestTransaction,
  stackedTimestamp,
  getStatusChip,
  textTruncator,
  getTicketDirection,
} from "../../../utils/auditutils";
import {
  Avatar,
  Button,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Container,
  Paper,
  IconButton,
  Stack,
  Box,
  Tooltip,
} from "@mui/material";
import {
  Close,
  ConfirmationNumber,
  AvTimer,
  Refresh,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionsById, selectAuditData } from "./audit";
import { selectActiveUserId } from "../user/user";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import TransactionTable from "./TransactionTable";
import TransactionLog from "./TransactionLog";
import { useSnackbar } from "notistack";

function TransactionDialog() {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("table");
  const [activeTransaction, setActiveTransaction] = useState(null);
  const activeSubscriberId = useSelector(selectActiveUserId);
  const transactions = useSelector((state) => state.audit.transactions);
  let auditData = useSelector(selectAuditData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  let id =
    searchParams.get("transactionId") || localStorage.getItem("transactionId");
  let pairedSubscriberId =
    searchParams.get("pairedSubscriberId") ||
    localStorage.getItem("pairedSubscriberId");
  let record = auditData.find((t) => t.id === id);

  const handleClose = () => {
    localStorage.removeItem("transactionId");
    localStorage.removeItem("pairedSubscriberId");
    navigate("/", { replace: true }); // Redirect to the home page
    setOpen(false);
    setActiveTransaction(null);
    setView("table");
  };

  const getTransactions = (activeSubscriberId, pairedSubscriberId, id) => {
    dispatch(
      getTransactionsById({ activeSubscriberId, pairedSubscriberId, id })
    );
  };

  useEffect(() => {
    let id =
      searchParams.get("transactionId") ||
      localStorage.getItem("transactionId");
    let pairedSubscriberId =
      searchParams.get("pairedSubscriberId") ||
      localStorage.getItem("pairedSubscriberId");
    if (id && pairedSubscriberId) {
      setOpen(true);
      getTransactions(activeSubscriberId, pairedSubscriberId, id);
    } else {
      if (open) setOpen(false);
    }
  }, [searchParams]);

  const columns = useMemo(
    () => [
      {
        id: "timestamp",
        Header: "Timestamp",
        accessor: "timestamp",
        Cell: (row) => stackedTimestamp(row.value),
      },
      {
        Header: "Additional Info",
        accessor: "additionalInfo",
        Cell: (row) => {
          let desc = row.row.original.additionalInfo;
          return textTruncator(desc);
        },
      },
      {
        id: "direction",
        Header: "Direction",
        accessor: "status",
        Cell: (row) => {
          let source = row.row.original.source;
          return getTicketDirection(activeSubscriberId, source);
        },
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          let status = row.row.original.status;
          return getStatusChip(status);
        },
      },
      {
        id: "log",
        Header: "Log",
        accessor: "",
        Cell: (row) => {
          let transaction = row.row.original;
          return (
            <Box sx={{ display: "flex" }}>
              <Button
                sx={{ mr: 1, color: "black", border: "1px solid black" }}
                size="small"
                variant="outlined"
                endIcon={<img src="images/log.svg" width={13} />}
                onClick={() => {
                  setActiveTransaction(transaction);
                  handleViewChange("log");
                }}
              >
                Log
              </Button>
            </Box>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(() => transactions.data, [transactions]);

  const handleViewChange = (view) => {
    setView(view);
  };

  const RecordDetails = ({ transactions, record, id }) => {
    if (!transactions || !record) return null;
    let latest = getLatestTransaction(transactions);
    let oldest = getOldestTransaction(transactions);
    let ticketOverview = [
      {
        id: "ticket created",
        icon: (
          <Tooltip title="Created">
            <img
              src="images/ticket created.svg"
              alt="ticket created icon"
              width={24}
            />
          </Tooltip>
        ),
        value: (
          <Typography sx={{ marginLeft: 1 }}>
            {timeElapsedFromEpoc(oldest.timestamp)}
          </Typography>
        ),
      },
      {
        id: "ticket updated",
        icon: (
          <Tooltip title="Updated">
            <img
              src="images/ticket updated.svg"
              alt="ticket updated icon"
              width={24}
            />
          </Tooltip>
        ),
        value: (
          <Typography sx={{ marginLeft: 1 }}>
            {timeElapsedFromEpoc(latest.timestamp)}
          </Typography>
        ),
      },
      {
        id: "average response time",
        icon: (
          <Tooltip title="Average response time">
            <AvTimer color="primary" />
          </Tooltip>
        ),
        value: (
          <Typography variant="body1" sx={{ marginLeft: 1 }}>{`${Math.floor(
            Math.random() * 1000
          )} seconds`}</Typography>
        ),
      },
    ];

    return (
      <>
        <Grid container>
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 2,
              pt: 2,
            }}
          >
            <Box
              id="id-description-stack"
              sx={{ display: "flex", flexDirection: "column", ml: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: 800 }}>
                {record.record[activeSubscriberId]
                  ? record.record[activeSubscriberId]
                  : record.id}
              </Typography>
              <Typography variant="body1">{record.description}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              justifyContent: "end",
              alignItems: "start",
              display: "flex",
            }}
          >
            <IconButton onClick={handleClose} sx={{ mr: 2, mt: 1 }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Stack direction={"row"} sx={{ pl: 3, pt: 1, pb: 1 }}>
          {ticketOverview.map((item) => (
            <Grid
              item
              key={item.id}
              sx={{ display: "flex", alignItems: "center", marginRight: 2 }}
            >
              {item.icon}
              {item.value}
            </Grid>
          ))}
          <Grid item sx={{ ml: 1 }}>
            {getStatusChip(record.status)}
          </Grid>
        </Stack>
      </>
    );
  };

  let content;
  if (transactions.status === "loading") {
    content = (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <img
          src="images/1bonding_icon.svg"
          width={55}
          style={{
            animation: "spin 0.8s linear infinite",
          }}
        />
        <Typography variant="caption" sx={{ m: 2 }}>
          Loading...
        </Typography>
      </Container>
    );
  } else if (transactions.status === "succeeded") {
    if (view === "table" && tableData && tableData.length > 0) {
      content = (
        <>
          <RecordDetails transactions={tableData} record={record} />
          <DialogContent dividers sx={{ p: 0 }}>
            <Container maxWidth="xl">
              <Box sx={{ display: "flex", p: 1 }}>
                <Typography variant="body1" sx={{ mb: 1, mr: 1, pt: 1 }}>
                  Transactions ({tableData.length})
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => {
                      getTransactions(
                        activeSubscriberId,
                        record.pairedSubscriber.id,
                        record.id
                      );
                      enqueueSnackbar("Transaction list refreshed.");
                    }}
                    size="small"
                  >
                    <Refresh fontSize="small" color="primary" />
                  </IconButton>
                </Box>
              </Box>
              <Paper
                sx={{
                  backgroundColor: primaryBgc,
                  padding: 2,
                  marginBottom: 2,
                }}
                elevation={0}
              >
                <Paper
                  sx={{
                    p: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "c",
                  }}
                  elevation={0}
                >
                  <TransactionTable columns={columns} data={tableData} />
                </Paper>
              </Paper>
            </Container>
          </DialogContent>
        </>
      );
    } else if (view === "log") {
      content = (
        <TransactionLog
          transaction={activeTransaction}
          handleViewChange={handleViewChange}
          handleTransactionDialogClose={handleClose}
        />
      );
    }
  }

  return (
    <div id="audit-details">
      <Dialog
        fullWidth
        maxWidth={"1000px"}
        PaperProps={{ sx: { minHeight: "90%" } }}
        open={open}
        onClose={handleClose}
      >
        {content}
      </Dialog>
    </div>
  );
}

export default TransactionDialog;
