import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import {
  Table as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Paper,
  Skeleton,
  Box,
  Select,
  InputBase,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";

import TransactionDialog from "./TransactionDialog";
import { timeElapsedFromEpoc } from "../../../utils/jsutils";
import { useDispatch, useSelector } from "react-redux";

import {
  ArrowDropDown,
  ArrowDropUp,
  Refresh,
  Search,
} from "@mui/icons-material";
import {
  selectBond,
  selectBondsData,
  selectBondsStatus,
  selectedBondData,
} from "../bonds/bonds";
import { selectActiveUserId } from "../user/user";
import {
  getBondNameFromId,
  getStatusChip,
  stackedTimestamp,
  textTruncator,
} from "../../../utils/auditutils";
import { useSearchParams } from "react-router-dom";
import { generateAvatarColors } from "../../../utils/bondutil";
import { loadAudit } from "./audit";
import { useSnackbar } from "notistack";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (globalFilter === undefined) setValue(globalFilter);
  }, [globalFilter]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ position: "relative" }}>
        <InputBase
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          style={{
            fontSize: "0.9rem",
            border: "1px solid #c4c4c4",
            borderRadius: 5,
            padding: "5px 30px 5px 10px", // Adjust padding for icon
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: 10,
            transform: "translateY(-50%)",
          }}
        >
          <Search color="primary" fontSize="small" />
        </div>
      </div>
    </div>
  );
}

function BondFilter({ bonds, setFilter, filters }) {
  const [selectedBond, setSelectedBond] = useState("");

  const handleBondSelection = (name) => {
    setSelectedBond(name);
    setFilter("bond", name);
  };

  useEffect(() => {
    if (
      !filters.length ||
      (selectedBond &&
        filters.length &&
        filters.find((filter) => filter.id === "bond") === null)
    ) {
      setSelectedBond("");
    }
  }, [filters]);

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl size="small">
        <InputLabel>Bond</InputLabel>
        <Select
          value={selectedBond ? selectedBond : ""}
          label="Bond"
          sx={{ width: 200 }}
          onChange={(event) => handleBondSelection(event.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {bonds.map((b) => {
            let name = b.pairedSubscriber.name;
            return (
              <MenuItem
                key={b.pairedSubscriber.id}
                value={name}
                sx={{ fontWeight: 800, alignItems: "center" }}
              >
                <img
                  src="images/1bonding_icon_white.svg"
                  width={20}
                  height={20}
                  style={{
                    backgroundColor: generateAvatarColors(name, bonds),
                    padding: 3,
                    borderRadius: "0.5em 0",
                    marginRight: 5,
                  }}
                />
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

function getUniqueStatuses(data) {
  const uniqueStatuses = new Set();

  data.forEach((item) => {
    uniqueStatuses.add(item.status);
  });

  const uniqueStatusesArray = Array.from(uniqueStatuses);

  return uniqueStatusesArray;
}

function StatusFilter({ data, setFilter, filters }) {
  const [selectedStatus, setSelectedStatus] = useState("");
  let statuses = getUniqueStatuses(data);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setFilter("status", status);
  };

  useEffect(() => {
    if (
      !filters.length ||
      (selectedStatus &&
        filters.length &&
        filters.find((filter) => filter.id === "status") === null)
    ) {
      setSelectedStatus("");
    }
  }, [filters]);

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl size="small">
        <InputLabel>Status</InputLabel>
        <Select
          value={selectedStatus ? selectedStatus : ""}
          label="Status"
          sx={{ width: 200 }}
          onChange={(event) => handleStatusChange(event.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {statuses.map((status) => {
            return (
              <MenuItem key={status} value={status}>
                {getStatusChip(status)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

function Table({
  columns,
  data,
  handleRowClick,
  bonds,
  refreshAudit,
  deselectBond,
}) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setAllFilters,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      autoResetFilters: false,
      initialState: {
        sortBy: [{ id: "lastTransaction", desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <Grid container spacing={0} sx={{ overflow: "auto" }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Stack direction="row" spacing={3}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <BondFilter
            setFilter={setFilter}
            bonds={bonds}
            filters={state.filters}
          />
          <StatusFilter
            setFilter={setFilter}
            data={data}
            filters={state.filters}
          />
          <Button
            color="primary"
            sx={{ fontWeight: 800 }}
            size="small"
            onClick={() => {
              setAllFilters([]);
              setGlobalFilter("");
              deselectBond();
            }}
          >
            Clear all
          </Button>
        </Stack>
        <Box>
          <Tooltip title="Refresh tickets" arrow>
            <IconButton size="small" onClick={() => refreshAudit()}>
              <Refresh fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MaUTable {...getTableProps()} size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Add getSortByToggleProps
                    sx={{
                      fontWeight: 600,
                      verticalAlign: "middle",
                      width: column.width,
                    }}
                    color="primary"
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDropDown fontSize="small" />
                        ) : (
                          <ArrowDropUp fontSize="small" />
                        )
                      ) : (
                        <ArrowDropUp fontSize="small" sx={{ color: "white" }} />
                      )}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(row.original)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          maxWidth: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </Grid>
    </Grid>
  );
}

function AuditTable({ audit }) {
  const bonds = useSelector(selectBondsData);
  const bondsStatus = useSelector(selectBondsStatus);
  const selectedBond = useSelector(selectedBondData);
  const activeSubscriberId = useSelector(selectActiveUserId);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleRowClick = (data) => {
    setSearchParams({
      transactionId: data.id,
      pairedSubscriberId: data.pairedSubscriber.id,
    });
  };

  const tableData = useMemo(
    () =>
      selectedBond
        ? audit.filter(
            (item) =>
              item.pairedSubscriber.id === selectedBond.pairedSubscriber.id
          )
        : audit,
    [audit, selectedBond, bonds]
  );

  useEffect(() => {}, [audit, selectedBond]);

  const refreshAudit = () => {
    dispatch(loadAudit(activeSubscriberId));
    enqueueSnackbar("Ticket list refreshed", { variant: "s" });
  };

  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        Cell: (row) =>
          row.row.original.record[activeSubscriberId]
            ? row.row.original.record[activeSubscriberId]
            : row.row.original.id,
        width: 100,
      },
      {
        id: "timestamp",
        Header: "Created",
        accessor: "timestamp",
        Cell: (row) =>
          row.row.original.timestamp &&
          stackedTimestamp(row.row.original.timestamp),
        width: 108,
      },
      {
        id: "lastTransaction",
        Header: "Last Transaction",
        accessor: "record.lastTransaction",
        Cell: (row) => {
          let lastTransaction = row.row.original.record.lastTransaction;
          let timestamp = row.row.original.record.timestamp;
          let displayedTimestamp;

          //if older than a day, return timestamp. else return "human formatting"
          if (lastTransaction && timestamp) {
            let lastTransactionNumber = Number(lastTransaction);
            let lastTimestampNumber = Number(timestamp);
            if (lastTimestampNumber > lastTransactionNumber) {
              displayedTimestamp = lastTimestampNumber;
            } else {
              displayedTimestamp = lastTransactionNumber;
            }
          } else if (lastTransaction) {
            displayedTimestamp = lastTransaction;
          }

          const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
          if (displayedTimestamp > twentyFourHoursAgo) {
            return stackedTimestamp(displayedTimestamp);
          } else {
            return timeElapsedFromEpoc(displayedTimestamp);
          }
        },
        width: 108,
      },
      {
        id: "bond",
        Header: "Bond",
        accessor: "pairedSubscriber.name",
        Cell: (row) => {
          let pairedSubscriberName = row.row.original.pairedSubscriber.name;
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src="images/1bonding_icon_white.svg"
                width={20}
                height={20}
                style={{
                  backgroundColor: generateAvatarColors(
                    pairedSubscriberName,
                    bonds
                  ),
                  padding: 3,
                  borderRadius: "0.5em 0",
                  marginRight: 5,
                }}
              />
              <Typography>{pairedSubscriberName}</Typography>
            </Box>
          );
        },
        width: 100,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: (row) => {
          let desc = row.row.original.record.description;
          return textTruncator(desc);
        },
        width: 350,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => getStatusChip(row.row.original.status),
        width: 100,
      },
    ],
    []
  );

  const deselectBond = () => {
    dispatch(selectBond(null));
  };

  let content;
  if (bondsStatus === "loading") {
    content = (
      <Stack>
        <Skeleton variant="rectangle" height={35}></Skeleton>
        <Skeleton variant="rectangle" height={35}></Skeleton>
        <Skeleton variant="rectangle" height={35}></Skeleton>
        <Skeleton variant="rectangle" height={35}></Skeleton>
        <Skeleton variant="rectangle" height={35}></Skeleton>
      </Stack>
    );
  } else if (bondsStatus === "succeeded" && bonds && tableData.length > 0) {
    content = (
      <>
        <Table
          columns={columns}
          data={tableData}
          handleRowClick={handleRowClick}
          bonds={bonds}
          refreshAudit={refreshAudit}
          deselectBond={deselectBond}
        />
        <TransactionDialog />
      </>
    );
  } else if (bondsStatus === "succeeded" && bonds && tableData.length === 0) {
    content = <Typography variant="subtitle2">No tickets yet.</Typography>;
  } else if (bondsStatus === "failed") {
    content = <Typography variant="subtitle2">Error fetching data</Typography>;
  }

  return (
    <Paper
      sx={{ p: 1, display: "flex", justifyContent: "center", alignItems: "c" }}
      elevation={0}
    >
      {content}
    </Paper>
  );
}

export default AuditTable;
