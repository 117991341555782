import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./state/store";
import { SnackbarProvider } from "notistack";
import { KeycloakProvider } from "keycloak-react-web";
import keycloak from "./apis/keycloak";
import { ThemeProvider } from "@mui/material";
import theme from "./themes/palette/theme";
import ScrollToTop from "./pages/components/ScrollToTop";
import "./themes/fonts/fonts.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <KeycloakProvider client={keycloak}>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            autoHideDuration={3000}
          >
            <App />
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </KeycloakProvider>
);
