import { createTheme } from "@mui/material/styles";
import { primaryBgc } from "../../utils/jsutils";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Century Gothic",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1f3f66",
    },
    secondary: {
      main: "#f18944",
    },
    info: {
      main: "#ecf4ff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: ".75rem 0px .75rem 0px",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            borderRadius: ".75rem 0 .75rem 0",
          },
          color: "secondary",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { borderRadius: "1.5rem 0px 1.5rem 0px" },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "0 !important", //ignore custom border on menus.
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0, //ignore custom border on drawers.
          backgroundColor: primaryBgc,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: { borderRadius: ".75rem 0px .75rem 0px" },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: { borderRadius: ".8rem 0px .8rem 0px" },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { backgroundColor: "white" },
      },
    },
    MuiLink: {
      fontFamily: "inherit",
    },
  },
});

export default theme;
